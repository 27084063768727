import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from "reactflow";
import {
  deleteGroupMembership,
  postGroupMembership,
} from "../../../../requests/group_memberships";

import "reactflow/dist/style.css";

export function TreeDiagram({ groupMemberships, groups }) {
  const positionNodes = (nodes) => {
    const levels = {};
    const placedNodes = new Set();
    const nodeDescendantCount = {}; // To store the count of descendants for each node
    const nodePositions = {}; // To store positions before mapping

    // Function to recursively assign levels to nodes and calculate descendants
    const assignLevel = (node, level = 0) => {
      if (!levels[level]) {
        levels[level] = [];
      }
      levels[level].push(node);
      placedNodes.add(node.id);

      // Find all child nodes and assign the next level
      const children = nodes.filter((n) =>
        n.item.attributes.parentGroupIds.includes(parseInt(node.id, 10))
      );

      let totalDescendants = 0;
      children.forEach((child) => {
        totalDescendants += assignLevel(child, level + 1) + 1; // Add 1 for each direct child
      });

      nodeDescendantCount[node.id] = totalDescendants; // Store the number of descendants for this node
      return totalDescendants; // Return the total count of descendants
    };

    // Assign levels to all top-level nodes (those without parents)
    nodes
      .filter((node) => node.item.attributes.parentGroupIds.length === 0)
      .forEach((topNode) => assignLevel(topNode));

    const calculatePosition = (
      node,
      x = 0,
      y = 0,
      siblingOffset = 0,
      maxWidth = 0
    ) => {
      const childNodes = nodes.filter((n) =>
        n.item.attributes.parentGroupIds.includes(parseInt(node.id, 10))
      );

      // Set the node's position
      nodePositions[node.id] = {
        x: x + siblingOffset,
        y,
      };

      // Calculate total width required for this node and its children
      let accumulatedOffset = 0;
      childNodes.forEach((child) => {
        const offset = nodeDescendantCount[child.id]
          ? nodeDescendantCount[child.id] * 150
          : 300; // Adjusted horizontal spacing
        calculatePosition(child, x + accumulatedOffset, y + 300, 0, offset); // Increase vertical space and distribute horizontally
        accumulatedOffset += offset; // Add horizontal offset for the next sibling
      });

      return maxWidth || Math.max(200, accumulatedOffset); // Return the total width for this node's subtree
    };

    // Position all top-level nodes and their children
    let accumulatedXOffset = 0;
    nodes
      .filter((node) => node.item.attributes.parentGroupIds.length === 0)
      .forEach((topNode, index) => {
        const initialOffset = index * 400 + accumulatedXOffset; // Wider spacing between top-level nodes
        calculatePosition(topNode, initialOffset, 0);
        accumulatedXOffset += nodeDescendantCount[topNode.id] * 150; // Adjust X offset based on descendants
      });

    // Finally, map the nodes and set the new calculated positions
    const newPositionedNodes = nodes.map((node) => {
      return {
        ...node,
        position: nodePositions[node.id] || { x: 0, y: 0 }, // Set the calculated position or fallback
      };
    });

    return newPositionedNodes;
  };

  const groupNodes = groups.map((group) => ({
    id: group.id.toString(),
    item: group,
    position: { x: 0, y: 0 },
    data: { label: `(#${group.id}) ${group.attributes.name}` },
  }));

  const groupEdges = groupMemberships.map((membership) => ({
    id: membership.id.toString(),
    source: membership.attributes.groupId.toString(),
    target: membership.attributes.memberId.toString(),
  }));

  const [nodes] = useNodesState(positionNodes(groupNodes));
  const [edges, setEdges, onEdgesChange] = useEdgesState(groupEdges);

  // Handle edge deletion
  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      setEdges((eds) =>
        eds.filter(
          (edge) =>
            !deletedEdges.some((deletedEdge) => deletedEdge.id === edge.id)
        )
      );

      // Optional: Log deleted edge IDs for debugging
      deletedEdges.forEach((edge) => {
        deleteGroupMembership(edge.id);
      });
    },
    [setEdges]
  );

  const onConnect = useCallback(
    async (params) => {
      if (params.source === params.target) {
        return;
      }

      const payload = {
        group_id: params.source,
        member_type: "Group",
        member_id: params.target,
      };

      const response = await postGroupMembership(payload);

      // Assuming the response contains the `id` for the new membership
      const newEdge = {
        id: response.id.toString(), // Use the `response.id` as the edge id
        source: params.source,
        target: params.target,
        animated: params.animated || false, // Any other params you need to carry over
        label: params.label || null, // Optionally carry other parameters like a label
      };

      setEdges((eds) => [...eds, newEdge]);
    },
    [setEdges]
  );

  return (
    <div style={{ height: "500px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onConnect={onConnect}
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}

TreeDiagram.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        tag: PropTypes.string,
        userIds: PropTypes.arrayOf(PropTypes.number),
        groupIds: PropTypes.arrayOf(PropTypes.number),
        parentGroupIds: PropTypes.arrayOf(PropTypes.number),
        createdById: PropTypes.number,
      }),
    })
  ),
  groupMemberships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

export default TreeDiagram;
