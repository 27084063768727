import * as axiosService from "../axiosRequests";

const COMPANY_GROUPS_ENDPOINT = (companyId) =>
  `/admin/companies/${companyId}/groups.json`;

export async function postGroup(companyId, group) {
  const { createdById, disabled, groupIds, name, tag, userIds } = group;

  const response = await axiosService.axiosPost(
    COMPANY_GROUPS_ENDPOINT(companyId),
    {
      group: {
        disabled,
        name,
        tag,
        user_ids: userIds,
        group_ids: groupIds,
        created_by_id: createdById,
      },
    }
  );

  return response.data.data;
}

export default {
  postGroup,
};
