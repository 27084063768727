import React from "react";
import PropTypes from "prop-types";
import { GroupForm } from "../GroupForm/GroupForm";
import { putGroup } from "../../../../requests/groups";

export function EditGroupForm({ group, groups, users }) {
  const submitForm = async (groupPayload) => {
    await putGroup(group.id, groupPayload);
  };

  const parentGroups = groups.filter((companyGroup) =>
    group.attributes.parentGroupIds.includes(parseInt(companyGroup.id, 10))
  );

  const hasParents = parentGroups.length > 0;

  return (
    <div>
      {hasParents && (
        <div>
          <h4>Member of</h4>
          <ul>
            {parentGroups.map((companyGroup) => (
              <li key={companyGroup.id}>
                <a href={`/admin/groups/${companyGroup.id}/edit`}>
                  {companyGroup.attributes.name}
                </a>
              </li>
            ))}
          </ul>
          <hr />
        </div>
      )}

      <GroupForm
        defaultGroup={{
          name: group.attributes.name,
          disabled: group.attributes.disabled,
          userIds: group.attributes.userIds.map((userId) => userId.toString()),
          groupIds: group.attributes.groupIds.map((groupId) =>
            groupId.toString()
          ),
          tag: group.attributes.tag,
          createdById: group.attributes.createdById,
        }}
        groups={groups}
        users={users}
        onSubmit={submitForm}
      />
    </div>
  );
}

EditGroupForm.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      disabled: PropTypes.bool,
      name: PropTypes.string,
      tag: PropTypes.string,
      userIds: PropTypes.arrayOf(PropTypes.number),
      groupIds: PropTypes.arrayOf(PropTypes.number),
      parentGroupIds: PropTypes.arrayOf(PropTypes.number),
      createdById: PropTypes.number,
    }),
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        fullName: PropTypes.string,
      }),
    })
  ),
};

export default EditGroupForm;
