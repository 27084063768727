import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { Button, Flex } from "@wingmate/toolkit";
import { DateFilter } from "./DateFilter";
import { Spinner } from "./Spinner";

import "./Filters.scss";

const TERMINAL_TAG_NAME = "terminal";
const REGION_TAG_NAME = "region";
const PROVINCE_TAG_NAME = "province";
const BUSINESS_UNIT_TAG_NAME = "business unit";
const TERMINAL_BUSINESS_UNIT_TAG_NAME = "terminal + business unit";
const REGION_BUSINESS_UNIT_TAG_NAME = "region + business unit";
const PROVINCE_BUSINESS_UNIT_TAG_NAME = "province + business unit";

export function Filters({
  campaigns,
  defaultFilters,
  groups,
  isLoading,
  onSubmit,
}) {
  const [filters, setFilters] = useState(defaultFilters);

  const modifyFilters = (newFilters) => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const onUserGroupingChange = (values, options) => {
    const campaignIds = options
      .filter((option) => option.type === "campaign")
      .map((option) => option.id);

    const groupIds = options
      .filter((option) => option.type === "group")
      .map((option) => option.id);

    modifyFilters({
      groupIds,
      campaignIds,
    });
  };

  const isGroupARegion = (group) => group.attributes.tag === REGION_TAG_NAME;
  const isGroupATerminal = (group) =>
    group.attributes.tag === TERMINAL_TAG_NAME;
  const isGroupAProvince = (group) =>
    group.attributes.tag === PROVINCE_TAG_NAME;
  const isGroupABusinessUnit = (group) =>
    group.attributes.tag === BUSINESS_UNIT_TAG_NAME;
  const isGroupATerminalBusinessUnit = (group) =>
    group.attributes.tag === TERMINAL_BUSINESS_UNIT_TAG_NAME;
  const isGroupARegionBusinessUnit = (group) =>
    group.attributes.tag === REGION_BUSINESS_UNIT_TAG_NAME;
  const isGroupAProvinceBusinessUnit = (group) =>
    group.attributes.tag === PROVINCE_BUSINESS_UNIT_TAG_NAME;

  const terminalGroups = groups.filter((group) => isGroupATerminal(group));
  const regionGroups = groups.filter((group) => isGroupARegion(group));
  const provinceGroups = groups.filter((group) => isGroupAProvince(group));
  const businessUnitGroups = groups.filter((group) =>
    isGroupABusinessUnit(group)
  );
  const terminalBusinessUnitGroups = groups.filter((group) =>
    isGroupATerminalBusinessUnit(group)
  );
  const regionBusinessUnitGroups = groups.filter((group) =>
    isGroupARegionBusinessUnit(group)
  );
  const provinceBusinessUnitGroups = groups.filter((group) =>
    isGroupAProvinceBusinessUnit(group)
  );

  let defaultCampaignAndGroupIds = [];

  if (filters.campaignIds) {
    defaultCampaignAndGroupIds = defaultCampaignAndGroupIds.concat(
      filters.campaignIds.map((id) => `campaign_${id}`)
    );
  }

  if (filters.groupIds) {
    defaultCampaignAndGroupIds = defaultCampaignAndGroupIds.concat(
      filters.groupIds.map((id) => `group_${id}`)
    );
  }

  return (
    <Flex className="PurolatorFilters" align="center">
      {isLoading && <Spinner />}
      <DateFilter
        defaultInterval={filters.interval}
        defaultEndDate={filters.endDate}
        defaultStartDate={filters.startDate}
        onChange={modifyFilters}
      />
      <Select
        mode="multiple"
        defaultValue={defaultCampaignAndGroupIds}
        allowClear
        placeholder="Choose Terminal..."
        onChange={onUserGroupingChange}
        id="grouping"
        options={[
          {
            label: <span>Pipelines</span>,
            title: "Pipelines",
            options: campaigns.map((campaign) => ({
              label: <span>{campaign.attributes.name}</span>,
              value: `campaign_${campaign.id}`,
              type: "campaign",
              id: campaign.id,
            })),
          },
          {
            label: <span>Terminals</span>,
            title: "Terminals",
            options: terminalGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
          {
            label: <span>Regions</span>,
            title: "Regions",
            options: regionGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
          {
            label: <span>Provinces</span>,
            title: "Provinces",
            options: provinceGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
          {
            label: <span>Business Units</span>,
            title: "Business Units",
            options: businessUnitGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
          {
            label: <span>Terminals + Business Units</span>,
            title: "Terminals + Business Units",
            options: terminalBusinessUnitGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
          {
            label: <span>Regions + Business Units</span>,
            title: "Regions + Business Units",
            options: regionBusinessUnitGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
          {
            label: <span>Provinces + Business Units</span>,
            title: "Provinces + Business Units",
            options: provinceBusinessUnitGroups.map((group) => ({
              label: <span>{group.attributes.name}</span>,
              value: `group_${group.id}`,
              type: "group",
              id: group.id,
            })),
          },
        ]}
      />
      <Button type="filled" onClick={() => onSubmit(filters)} primary>
        Submit
      </Button>
    </Flex>
  );
}

Filters.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
      id: PropTypes.string,
    })
  ),
  defaultFilters: PropTypes.shape({
    campaignIds: PropTypes.arrayOf(PropTypes.string),
    endDate: PropTypes.string,
    groupIds: PropTypes.arrayOf(PropTypes.string),
    interval: PropTypes.string,
    startDate: PropTypes.string,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
      id: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Filters;
