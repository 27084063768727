import * as axiosService from "./axiosRequests";

const GROUP_MEMBERSHIPS_ENDPOINT = `/admin/group_memberships.json`;
const GROUP_MEMBERSHIP_ENDPOINT = (id) => `/admin/group_memberships/${id}.json`;

export async function postGroupMembership(payload) {
  const response = await axiosService.axiosPost(GROUP_MEMBERSHIPS_ENDPOINT, {
    group_membership: payload,
  });

  return response.data;
}

export async function deleteGroupMembership(id) {
  const response = await axiosService.axiosDelete(
    GROUP_MEMBERSHIP_ENDPOINT(id)
  );

  return response.data;
}

export default {
  postGroupMembership,
  deleteGroupMembership,
};
